import React from "react"

export default ({ ...props }) => (
  <svg
    viewBox="0 0 31 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.76504 10.9845V9.52682H1.83084C1.33449 9.52682 0.962232 9.4145 0.714058 9.18983C0.465884 8.96517 0.341797 8.65953 0.341797 8.2729C0.341797 8.17363 0.360087 8.07305 0.39666 7.97117C0.433233 7.86929 0.488088 7.76218 0.561234 7.64985C0.63438 7.53752 0.710143 7.42911 0.788508 7.32462C0.866881 7.22012 0.963537 7.09212 1.07848 6.9406L5.23212 1.38413C5.50381 1.0184 5.72716 0.753248 5.90219 0.588666C6.07721 0.424092 6.28751 0.341797 6.53307 0.341797C7.32722 0.341797 7.7243 0.796353 7.7243 1.70545V7.77133H8.06129C8.4636 7.77133 8.78622 7.82618 9.02917 7.9359C9.27211 8.04562 9.39359 8.28596 9.39359 8.65692C9.39359 8.95995 9.29432 9.18069 9.09578 9.31914C8.89724 9.4576 8.59943 9.52682 8.20236 9.52682H7.7243V10.9845C7.7243 11.3868 7.63548 11.6859 7.45784 11.8819C7.2802 12.0778 7.04248 12.1757 6.74467 12.1757C6.45208 12.1757 6.21567 12.0752 6.03541 11.874C5.85517 11.6729 5.76504 11.3764 5.76504 10.9845V10.9845ZM2.39511 7.77133H5.76504V3.218L2.39511 7.77133Z"
      fill="#D7385E"
    />
    <path
      d="M19.3669 6.28717C19.3669 7.14402 19.3147 7.8807 19.2102 8.49721C19.1057 9.11373 18.9124 9.67278 18.6303 10.1743C18.275 10.7909 17.8152 11.2663 17.2509 11.6007C16.6867 11.9351 16.0597 12.1023 15.37 12.1023C14.5759 12.1023 13.864 11.8776 13.2344 11.4283C12.6049 10.9789 12.1307 10.3572 11.812 9.56306C11.6501 9.1294 11.5312 8.65004 11.4554 8.12495C11.3797 7.59987 11.3418 7.03169 11.3418 6.4204C11.3418 5.63146 11.3836 4.92091 11.4672 4.28872C11.5508 3.65653 11.6814 3.11316 11.859 2.65861C12.1673 1.90625 12.6153 1.33284 13.2031 0.938378C13.7909 0.543912 14.4923 0.34668 15.3073 0.34668C15.8455 0.34668 16.334 0.434194 16.7729 0.609222C17.2117 0.78425 17.5971 1.04026 17.9288 1.37725C18.2606 1.71425 18.5414 2.13092 18.7713 2.62726C19.1684 3.48934 19.3669 4.7093 19.3669 6.28717V6.28717ZM17.2196 6.13043C17.2196 5.17431 17.1621 4.39191 17.0472 3.78323C16.9322 3.17455 16.7389 2.71478 16.4672 2.4039C16.1955 2.09304 15.8194 1.93761 15.3387 1.93761C14.649 1.93761 14.1697 2.28504 13.9006 2.97993C13.6315 3.67482 13.497 4.74588 13.497 6.19312C13.497 7.17536 13.5545 7.97997 13.6694 8.60693C13.7843 9.2339 13.9777 9.70804 14.2493 10.0294C14.521 10.3507 14.8894 10.5113 15.3544 10.5113C15.835 10.5113 16.2112 10.3441 16.4829 10.0098C16.7546 9.67538 16.9453 9.19733 17.055 8.57559C17.1647 7.95385 17.2196 7.13879 17.2196 6.13043V6.13043Z"
      fill="#30B6FF"
    />
    <path
      d="M26.765 10.9845V9.52682H22.8308C22.3345 9.52682 21.9622 9.4145 21.7141 9.18983C21.4659 8.96517 21.3418 8.65953 21.3418 8.2729C21.3418 8.17363 21.3601 8.07305 21.3967 7.97117C21.4332 7.86929 21.4881 7.76218 21.5612 7.64985C21.6344 7.53752 21.7101 7.42911 21.7885 7.32462C21.8669 7.22012 21.9635 7.09212 22.0785 6.9406L26.2321 1.38413C26.5038 1.0184 26.7272 0.753248 26.9022 0.588666C27.0772 0.424092 27.2875 0.341797 27.5331 0.341797C28.3272 0.341797 28.7243 0.796353 28.7243 1.70545V7.77133H29.0613C29.4636 7.77133 29.7862 7.82618 30.0292 7.9359C30.2721 8.04562 30.3936 8.28596 30.3936 8.65692C30.3936 8.95995 30.2943 9.18069 30.0958 9.31914C29.8972 9.4576 29.5994 9.52682 29.2024 9.52682H28.7243V10.9845C28.7243 11.3868 28.6355 11.6859 28.4578 11.8819C28.2802 12.0778 28.0425 12.1757 27.7447 12.1757C27.4521 12.1757 27.2157 12.0752 27.0354 11.874C26.8552 11.6729 26.765 11.3764 26.765 10.9845V10.9845ZM23.3951 7.77133H26.765V3.218L23.3951 7.77133Z"
      fill="#00C756"
    />
  </svg>
)
